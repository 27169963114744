<template>
    <div id="main-wrapper">
        <div class="section fx-pairs">
            <div class="links-holder tabs-forex wow fadeIn pt-5">
                <div id="forex" class="tab-holder">
                    <div class="table-holder wow fadeInRight home-table">
                        <table>
                            <tr>
                            <td colspan="3"><h3>Annual Losers %</h3></td> 
                            </tr>
                            <tr>
                                <td>Month (01/10/2022   - 31/10/2022)</td>
                                <td>49%</td>
                                <td>51%</td>
                            </tr>
                            <tr>
                                <td>Quarter (01/08/2022 - 31/10/2022)</td>
                                <td>34%</td>
                                <td>66%</td>
                            </tr>
                            <tr>
                                <td>Semester (01/05/2022 - 31/10/2022)</td>
                                <td>29%</td>
                                <td>71%</td>
                            </tr>
                            <tr>
                                <td>Annual (31/10/2021 - 31/10/2022)</td>
                                <td>20%</td>
                                <td>80%</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.pt-5 {
    padding-top: 150px;
}
</style>